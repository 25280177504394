import { fnv32a } from '@trmediaab/zebra-utils';
import dotProp from 'dot-prop-immutable';
import partition from 'lodash/partition';

import { UI_SCALE_AUTO } from 'shared/constants/AppConstants';
import {
  ALERT,
  LOGGED_IN_TO_OTHER_DEVICE,
  PROMO,
  SESSION_EXPIRED,
  USER_LOGGED_OUT_BY_SERVER,
} from 'shared/constants/MessageTypes';
import { MESSAGES_PRIVATE } from 'shared/constants/StompKind';

import * as types from '../actionTypes';

const defaultState = {
  documentHidden: false,
  loader: {
    visible: true,
    labelProp: null,
  },
  scale: UI_SCALE_AUTO,
  userTemplatesVisible: true,
  jokerTemplatesVisible: true,
  siteMessages: [],
  dismissedSiteMessagesByHash: {
    local: [],
    persist: [],
  },
};

function getMessageHash(message) {
  const { id, clientId, message: text } = message;
  return id ? fnv32a(id + text) : clientId;
}

const isMatchingMessages = (newMessage, oldMessage) =>
  newMessage.id === oldMessage.id ||
  (newMessage.messageType !== ALERT &&
    newMessage.messageType === oldMessage.messageType);

const isUpdatedMessage = (newMessage, oldMessage, state) =>
  isMatchingMessages(newMessage, oldMessage) &&
  !state.dismissedSiteMessagesByHash.local.includes(oldMessage.hash) &&
  !state.dismissedSiteMessagesByHash.persist.includes(oldMessage.hash);

function addSiteMessages(state, messageType, messages) {
  const messagesArray = Array.isArray(messages) ? messages : [messages];

  // Ignore client session message if we've got one from the server (over the socket)
  if (
    messageType === USER_LOGGED_OUT_BY_SERVER &&
    state.siteMessages.some(message =>
      [SESSION_EXPIRED, LOGGED_IN_TO_OTHER_DEVICE].includes(
        message.messageType,
      ),
    )
  ) {
    return state;
  }

  const [updatedMessages, newMessages] = partition(messagesArray, message =>
    state.siteMessages.some(oldMessage =>
      isUpdatedMessage(message, oldMessage, state),
    ),
  );

  return dotProp.set(state, 'siteMessages', oldMessages => [
    ...newMessages.map(message => ({
      ...message,
      messageType,
      hash: getMessageHash(message),
    })),
    ...oldMessages.reduce((acc, oldMessage) => {
      const updatedMessage = updatedMessages.find(message =>
        isMatchingMessages(message, oldMessage),
      );

      // Filter out unpublished alert messages
      if (
        messageType === ALERT &&
        oldMessage.messageType === ALERT &&
        updatedMessage == null
      ) {
        return acc;
      }

      const clientId = oldMessage.clientId;

      acc.push({
        ...oldMessage,
        ...updatedMessage,
        clientId,
      });

      return acc;
    }, []),
  ]);
}

export default function reducer(state = defaultState, action = {}) {
  switch (action.type) {
    case types.UPDATE_PAGE_VISIBILITY:
      return dotProp.set(state, 'documentHidden', action.payload);

    case types.CHANGE_UI_SCALE:
      return dotProp.set(state, 'scale', action.payload);

    case types.TOGGLE_USER_TEMPLATES_VISIBILITY:
      return dotProp.toggle(state, 'userTemplatesVisible');

    case types.TOGGLE_JOKER_TEMPLATES_VISIBILITY:
      return dotProp.toggle(state, 'jokerTemplatesVisible');

    case types.RECEIVE_PROMO_MESSAGE: {
      if (action.payload.results.length > 0) {
        const { headline, links, id } = action.payload.results[0];
        return addSiteMessages(state, PROMO, {
          message: headline,
          link: links?.[0],
          id,
        });
      } else {
        return state;
      }
    }

    case types.RECEIVE_PROMO_ERROR:
      return dotProp.set(state, PROMO, action.payload);

    case types.RECEIVE_ALERT_MESSAGE: {
      const { messages } = action.payload;

      if (messages.length > 0) {
        const messagesArray = messages.map(message => ({
          message: message.text,
          id: message.id,
        }));

        return addSiteMessages(state, ALERT, messagesArray);
      } else {
        return state;
      }
    }

    case types.RECEIVE_ALERT_ERROR:
      return dotProp.set(state, ALERT, action.payload);

    case types.SHOW_LOADER:
      return dotProp.set(state, 'loader', {
        visible: true,
        labelProp: action.payload,
      });

    case types.HIDE_LOADER:
      return dotProp.set(state, 'loader', {
        visible: false,
        labelProp: null,
      });

    case types.ADD_SITE_MESSAGE: {
      const { messageType, message } = action.payload;
      return addSiteMessages(state, messageType, [message]);
    }

    case types.STOMP_RECEIVE_DATA: {
      const { kind } = action.payload;
      const { clientId } = action.meta;

      switch (kind) {
        case MESSAGES_PRIVATE: {
          const {
            data: { messageType },
          } = action.payload;
          if (
            [SESSION_EXPIRED, LOGGED_IN_TO_OTHER_DEVICE].includes(messageType)
          ) {
            return addSiteMessages(state, messageType, { clientId });
          }

          break;
        }
        default:
        // Do nothing
      }
      return state;
    }

    case types.DISMISS_SITE_MESSAGE_BY_SERVER_ID:
    case types.DISMISS_SITE_MESSAGE_BY_CLIENT_ID: {
      const path = `dismissedSiteMessagesByHash.${
        action.type === types.DISMISS_SITE_MESSAGE_BY_SERVER_ID
          ? 'persist'
          : 'local'
      }`;
      return dotProp.set(state, path, dismissed => [
        ...dismissed,
        action.payload.hash,
      ]);
    }

    case types.DISMISS_SITE_MESSAGE_BY_TYPE: {
      const path = `dismissedSiteMessagesByHash.${
        action.payload === ALERT ? 'persist' : 'local'
      }`;

      return dotProp.set(state, path, dismissed => [
        ...dismissed,
        ...state.siteMessages
          .filter(message => message.messageType === action.payload)
          .map(message => message.hash),
      ]);
    }

    default:
      return state;
  }
}
