// Actions for multiple bets
export const REQUEST_ARCHIVED_BETS = 'SPORT/REQUEST_ARCHIVED_BETS';
export const RECEIVE_ARCHIVED_BETS = 'SPORT/RECEIVE_ARCHIVED_BETS';
export const RECEIVE_ARCHIVED_BETS_ERROR = 'SPORT/RECEIVE_ARCHIVED_BETS_ERROR';
export const REQUEST_DELETE_BETS = 'SPORT/REQUEST_DELETE_BETS';
export const RECEIVE_DELETE_BETS = 'SPORT/RECEIVE_DELETE_BETS';
export const RECEIVE_DELETE_BETS_ERROR = 'SPORT/RECEIVE_DELETE_BETS_ERROR';

// Actions for single bet
export const REQUEST_CREATE_BET = 'SPORT/REQUEST_CREATE_BET';
export const RECEIVE_CREATE_BET = 'SPORT/RECEIVE_CREATE_BET';
export const RECEIVE_CREATE_BET_ERROR = 'SPORT/RECEIVE_CREATE_BET_ERROR';
export const REQUEST_BET = 'SPORT/REQUEST_BET';
export const RECEIVE_BET = 'SPORT/RECEIVE_BET';
export const RECEIVE_BET_ERROR = 'SPORT/RECEIVE_BET_ERROR';
export const REQUEST_UPDATE_BET = 'SPORT/REQUEST_UPDATE_BET';
export const RECEIVE_UPDATE_BET = 'SPORT/RECEIVE_UPDATE_BET';
export const RECEIVE_UPDATE_BET_ERROR = 'SPORT/RECEIVE_UPDATE_BET_ERROR';
export const REQUEST_DUPLICATE_BET = 'SPORT/REQUEST_DUPLICATE_BET';
export const RECEIVE_DUPLICATE_BET = 'SPORT/RECEIVE_DUPLICATE_BET';
export const RECEIVE_DUPLICATE_BET_ERROR = 'SPORT/RECEIVE_DUPLICATE_BET_ERROR';
export const REQUEST_SHARE_CODE = 'SPORT/REQUEST_SHARE_CODE';
export const RECEIVE_SHARE_CODE = 'SPORT/RECEIVE_SHARE_CODE';
export const RECEIVE_SHARE_CODE_ERROR = 'SPORT/RECEIVE_SHARE_CODE_ERROR';
export const REQUEST_SHARED_BET = 'SPORT/REQUEST_SHARED_BET';
export const RECEIVE_SHARED_BET = 'SPORT/RECEIVE_SHARED_BET';
export const RECEIVE_SHARED_BET_ERROR = 'SPORT/RECEIVE_SHARED_BET_ERROR';
export const ADD_MATCHWINNERS = 'SPORT/ADD_MATCHWINNERS';
export const REMOVE_MATCHWINNERS = 'SPORT/REMOVE_MATCHWINNERS';
export const ADD_BIG9_CONDITION = 'SPORT/ADD_BIG9_CONDITION';
export const REMOVE_BIG9_CONDITION = 'SPORT/REMOVE_BIG9_CONDITION';
export const ADD_HOME_SCORE = 'SPORT/ADD_HOME_SCORE';
export const REMOVE_HOME_SCORE = 'SPORT/REMOVE_HOME_SCORE';
export const ADD_AWAY_SCORE = 'SPORT/ADD_AWAY_SCORE';
export const REMOVE_AWAY_SCORE = 'SPORT/REMOVE_AWAY_SCORE';
export const TOGGLE_BET_LOCKED = 'SPORT/TOGGLE_BET_LOCKED';
export const CHANGE_BET_NAME = 'SPORT/CHANGE_BET_NAME';
export const CHANGE_AMOUNT_PER_ROW = 'SPORT/CHANGE_AMOUNT_PER_ROW';

// Wager actions
export const REQUEST_PLACE_BET = 'SPORT/REQUEST_PLACE_BET';
export const RECEIVE_PLACE_BET = 'SPORT/RECEIVE_PLACE_BET';
export const RECEIVE_PLACE_BET_ERROR = 'SPORT/RECEIVE_PLACE_BET_ERROR';
export const REQUEST_PRE_PLACE_BET = 'SPORT/REQUEST_PRE_PLACE_BET';
export const RECEIVE_PRE_PLACE_BET = 'SPORT/RECEIVE_PRE_PLACE_BET';
export const RECEIVE_PRE_PLACE_BET_ERROR = 'SPORT/RECEIVE_PRE_PLACE_BET_ERROR';
export const DELETE_WAGER_DATA = 'SPORT/DELETE_WAGER_DATA';
export const WAGER_SENT = 'SPORT/WAGER_SENT';

// Coupon actions
export const REQUEST_COUPONS = 'SPORT/REQUEST_COUPONS';
export const RECEIVE_COUPONS = 'SPORT/RECEIVE_COUPONS';
export const RECEIVE_COUPONS_ERROR = 'SPORT/RECEIVE_COUPONS_ERROR';
export const CHANGE_SORT_COUPONS_BY_CORRECT_RESULT =
  'SPORT/CHANGE_SORT_COUPONS_BY_CORRECT_RESULT';
export const CHANGE_SHOW_COUPONS_RESULT = 'SPORT/CHANGE_SHOW_COUPONS_RESULT';

// Result actions
export const DISMISS_MATCH_RESULT_UPDATE = 'SPORT/DISMISS_MATCH_RESULT_UPDATE';

// Base reduction actions
export const ADD_BASE_REDUCTION = 'SPORT/ADD_BASE_REDUCTION';
export const DELETE_BASE_REDUCTION = 'SPORT/DELETE_BASE_REDUCTION';
export const WILL_DELETE_BASE_REDUCTION = 'SPORT/WILL_DELETE_BASE_REDUCTION';
export const TOGGLE_BASE_REDUCTION_ENABLED =
  'SPORT/TOGGLE_BASE_REDUCTION_ENABLED';
export const ADD_BASE_REDUCTION_WINNER = 'SPORT/ADD_BASE_REDUCTION_WINNER';
export const REMOVE_BASE_REDUCTION_WINNER =
  'SPORT/REMOVE_BASE_REDUCTION_WINNER';
export const ADD_BASE_REDUCTION_BIG9_CONDITION =
  'SPORT/ADD_BASE_REDUCTION_BIG9_CONDITION';
export const REMOVE_BASE_REDUCTION_BIG9_CONDITION =
  'SPORT/REMOVE_BASE_REDUCTION_BIG9_CONDITION';
export const CHANGE_MIN_BASE_WINNERS = 'SPORT/CHANGE_MIN_BASE_WINNERS';
export const CHANGE_MAX_BASE_WINNERS = 'SPORT/CHANGE_MAX_BASE_WINNERS';
export const CHANGE_BASE_COMMENT = 'SPORT/CHANGE_BASE_COMMENT';
export const ADD_BOMBEN_BASE_REDUCTION = 'SPORT/ADD_BOMBEN_BASE_REDUCTION';
export const DELETE_BOMBEN_BASE_REDUCTION =
  'SPORT/DELETE_BOMBEN_BASE_REDUCTION';
export const WILL_DELETE_BOMBEN_BASE_REDUCTION =
  'SPORT/WILL_DELETE_BOMBEN_BASE_REDUCTION';
export const TOGGLE_BOMBEN_BASE_REDUCTION_ENABLED =
  'SPORT/TOGGLE_BOMBEN_BASE_REDUCTION_ENABLED';
export const ADD_BOMBEN_BASE_REDUCTION_SCORE =
  'SPORT/ADD_BOMBEN_BASE_REDUCTION_SCORE';
export const REMOVE_BOMBEN_BASE_REDUCTION_SCORE =
  'SPORT/REMOVE_BOMBEN_BASE_REDUCTION_SCORE';
export const CHANGE_BOMBEN_MIN_BASE_SCORES =
  'SPORT/CHANGE_BOMBEN_MIN_BASE_SCORES';
export const CHANGE_BOMBEN_MAX_BASE_SCORES =
  'SPORT/CHANGE_BOMBEN_MAX_BASE_SCORES';
export const CHANGE_BOMBEN_BASE_COMMENT = 'SPORT/CHANGE_BOMBEN_BASE_COMMENT';

// Outcome reduction actions
export const DELETE_OUTCOME_REDUCTION = 'SPORT/DELETE_OUTCOME_REDUCTION';
export const TOGGLE_OUTCOME_REDUCTION_ENABLED =
  'SPORT/TOGGLE_OUTCOME_REDUCTION_ENABLED';
export const CHANGE_MIN_HOME_WINNERS = 'SPORT/CHANGE_MIN_HOME_WINNERS';
export const CHANGE_MAX_HOME_WINNERS = 'SPORT/CHANGE_MAX_HOME_WINNERS';
export const CHANGE_MIN_DRAWS = 'SPORT/CHANGE_MIN_DRAWS';
export const CHANGE_MAX_DRAWS = 'SPORT/CHANGE_MAX_DRAWS';
export const CHANGE_MIN_AWAY_WINNERS = 'SPORT/CHANGE_MIN_AWAY_WINNERS';
export const CHANGE_MAX_AWAY_WINNERS = 'SPORT/CHANGE_MAX_AWAY_WINNERS';
export const CHANGE_MIN_FAVORITES = 'SPORT/CHANGE_MIN_FAVORITES';
export const CHANGE_MAX_FAVORITES = 'SPORT/CHANGE_MAX_FAVORITES';
export const CHANGE_MIN_UPSETS = 'SPORT/CHANGE_MIN_UPSETS';
export const CHANGE_MAX_UPSETS = 'SPORT/CHANGE_MAX_UPSETS';
export const CHANGE_MIN_GOALS_OVER = 'SPORT/CHANGE_MIN_GOALS_OVER';
export const CHANGE_MAX_GOALS_OVER = 'SPORT/CHANGE_MAX_GOALS_OVER';
export const CHANGE_MIN_GOALS_UNDER = 'SPORT/CHANGE_MIN_GOALS_UNDER';
export const CHANGE_MAX_GOALS_UNDER = 'SPORT/CHANGE_MAX_GOALS_UNDER';
export const CHANGE_NUM_ERRORS_GUARANTEE = 'SPORT/CHANGE_NUM_ERRORS_GUARANTEE';
export const DELETE_BOMBEN_OUTCOME_REDUCTION =
  'SPORT/DELETE_BOMBEN_OUTCOME_REDUCTION';
export const TOGGLE_BOMBEN_OUTCOME_REDUCTION_ENABLED =
  'SPORT/TOGGLE_BOMBEN_OUTCOME_REDUCTION_ENABLED';
export const CHANGE_BOMBEN_MIN_HOME_WINNERS =
  'SPORT/CHANGE_BOMBEN_MIN_HOME_WINNERS';
export const CHANGE_BOMBEN_MAX_HOME_WINNERS =
  'SPORT/CHANGE_BOMBEN_MAX_HOME_WINNERS';
export const CHANGE_BOMBEN_MIN_DRAWS = 'SPORT/CHANGE_BOMBEN_MIN_DRAWS';
export const CHANGE_BOMBEN_MAX_DRAWS = 'SPORT/CHANGE_BOMBEN_MAX_DRAWS';
export const CHANGE_BOMBEN_MIN_AWAY_WINNERS =
  'SPORT/CHANGE_BOMBEN_MIN_AWAY_WINNERS';
export const CHANGE_BOMBEN_MAX_AWAY_WINNERS =
  'SPORT/CHANGE_BOMBEN_MAX_AWAY_WINNERS';
export const CHANGE_BOMBEN_MIN_TOTAL_GOALS =
  'SPORT/CHANGE_BOMBEN_MIN_TOTAL_GOALS';
export const CHANGE_BOMBEN_MAX_TOTAL_GOALS =
  'SPORT/CHANGE_BOMBEN_MAX_TOTAL_GOALS';

// Point reduction actions
export const ADD_POINT_REDUCTION = 'SPORT/ADD_POINT_REDUCTION';
export const WILL_DELETE_POINT_REDUCTION = 'SPORT/WILL_DELETE_POINT_REDUCTION';
export const DELETE_POINT_REDUCTION = 'SPORT/DELETE_POINT_REDUCTION';
export const TOGGLE_POINT_REDUCTION_ENABLED =
  'SPORT/TOGGLE_POINT_REDUCTION_ENABLED';
export const CHANGE_MATCH_POINT = 'SPORT/CHANGE_MATCH_POINT';
export const COPY_PEOPLES_MATCH_POINTS = 'SPORT/COPY_PEOPLES_MATCH_POINTS';
export const COPY_RANK_MATCH_POINTS = 'SPORT/COPY_RANK_MATCH_POINTS';
export const RESET_MATCH_POINTS = 'SPORT/RESET_MATCH_POINTS';
export const ADD_SUM_REDUCTION = 'SPORT/ADD_SUM_REDUCTION';
export const DELETE_SUM_REDUCTION = 'SPORT/DELETE_SUM_REDUCTION';
export const CHANGE_SUM_REDUCTION_MIN = 'SPORT/CHANGE_SUM_REDUCTION_MIN';
export const CHANGE_SUM_REDUCTION_MAX = 'SPORT/CHANGE_SUM_REDUCTION_MAX';
export const ADD_POINT_SUM_BASED_FACTOR = 'SPORT/ADD_POINT_SUM_BASED_FACTOR';
export const DELETE_POINT_SUM_BASED_FACTOR =
  'SPORT/DELETE_POINT_SUM_BASED_FACTOR';
export const CHANGE_POINT_SUM_BASED_FACTOR_MIN =
  'SPORT/CHANGE_POINT_SUM_BASED_FACTOR_MIN';
export const CHANGE_POINT_SUM_BASED_FACTOR_MAX =
  'SPORT/CHANGE_POINT_SUM_BASED_FACTOR_MAX';
export const CHANGE_POINT_SUM_BASED_FACTOR_FACTOR =
  'SPORT/CHANGE_POINT_SUM_BASED_FACTOR_FACTOR';
export const ADD_INTERVAL_REDUCTION = 'SPORT/ADD_INTERVAL_REDUCTION';
export const DELETE_INTERVAL_REDUCTION = 'SPORT/DELETE_INTERVAL_REDUCTION';
export const CHANGE_INTERVAL_REDUCTION_MIN_POINT =
  'SPORT/CHANGE_INTERVAL_REDUCTION_MIN_POINT';
export const CHANGE_INTERVAL_REDUCTION_MAX_POINT =
  'SPORT/CHANGE_INTERVAL_REDUCTION_MAX_POINT';
export const CHANGE_INTERVAL_REDUCTION_MIN_MATCHES =
  'SPORT/CHANGE_INTERVAL_REDUCTION_MIN_MATCHES';
export const CHANGE_INTERVAL_REDUCTION_MAX_MATCHES =
  'SPORT/CHANGE_INTERVAL_REDUCTION_MAX_MATCHES';
export const ADD_BOMBEN_POINT_REDUCTION = 'SPORT/ADD_BOMBEN_POINT_REDUCTION';
export const WILL_DELETE_BOMBEN_POINT_REDUCTION =
  'SPORT/WILL_DELETE_BOMBEN_POINT_REDUCTION';
export const DELETE_BOMBEN_POINT_REDUCTION =
  'SPORT/DELETE_BOMBEN_POINT_REDUCTION';
export const TOGGLE_BOMBEN_POINT_REDUCTION_ENABLED =
  'SPORT/TOGGLE_BOMBEN_POINT_REDUCTION_ENABLED';
export const CHANGE_BOMBEN_MATCH_POINT = 'SPORT/CHANGE_BOMBEN_MATCH_POINT';
export const COPY_PEOPLES_BOMBEN_MATCH_POINTS =
  'SPORT/COPY_PEOPLES_BOMBEN_MATCH_POINTS';
export const RESET_BOMBEN_MATCH_POINTS = 'SPORT/RESET_BOMBEN_MATCH_POINTS';
export const ADD_BOMBEN_SUM_REDUCTION = 'SPORT/ADD_BOMBEN_SUM_REDUCTION';
export const DELETE_BOMBEN_SUM_REDUCTION = 'SPORT/DELETE_BOMBEN_SUM_REDUCTION';
export const CHANGE_BOMBEN_SUM_REDUCTION_MIN =
  'SPORT/CHANGE_BOMBEN_SUM_REDUCTION_MIN';
export const CHANGE_BOMBEN_SUM_REDUCTION_MAX =
  'SPORT/CHANGE_BOMBEN_SUM_REDUCTION_MAX';
export const ADD_BOMBEN_POINT_SUM_BASED_FACTOR =
  'SPORT/ADD_BOMBEN_POINT_SUM_BASED_FACTOR';
export const DELETE_BOMBEN_POINT_SUM_BASED_FACTOR =
  'SPORT/DELETE_BOMBEN_POINT_SUM_BASED_FACTOR';
export const CHANGE_BOMBEN_POINT_SUM_BASED_FACTOR_MIN =
  'SPORT/CHANGE_BOMBEN_POINT_SUM_BASED_FACTOR_MIN';
export const CHANGE_BOMBEN_POINT_SUM_BASED_FACTOR_MAX =
  'SPORT/CHANGE_BOMBEN_POINT_SUM_BASED_FACTOR_MAX';
export const CHANGE_BOMBEN_POINT_SUM_BASED_FACTOR_FACTOR =
  'SPORT/CHANGE_BOMBEN_POINT_SUM_BASED_FACTOR_FACTOR';
export const ADD_BOMBEN_INTERVAL_REDUCTION =
  'SPORT/ADD_BOMBEN_INTERVAL_REDUCTION';
export const DELETE_BOMBEN_INTERVAL_REDUCTION =
  'SPORT/DELETE_BOMBEN_INTERVAL_REDUCTION';
export const CHANGE_BOMBEN_INTERVAL_REDUCTION_MIN_POINT =
  'SPORT/CHANGE_BOMBEN_INTERVAL_REDUCTION_MIN_POINT';
export const CHANGE_BOMBEN_INTERVAL_REDUCTION_MAX_POINT =
  'SPORT/CHANGE_BOMBEN_INTERVAL_REDUCTION_MAX_POINT';
export const CHANGE_BOMBEN_INTERVAL_REDUCTION_MIN_MATCHES =
  'SPORT/CHANGE_BOMBEN_INTERVAL_REDUCTION_MIN_MATCHES';
export const CHANGE_BOMBEN_INTERVAL_REDUCTION_MAX_MATCHES =
  'SPORT/CHANGE_BOMBEN_INTERVAL_REDUCTION_MAX_MATCHES';

// Dividend reduction actions
export const DELETE_DIVIDEND_REDUCTION = 'SPORT/DELETE_DIVIDEND_REDUCTION';
export const TOGGLE_DIVIDEND_REDUCTION_ENABLED =
  'SPORT/TOGGLE_DIVIDEND_REDUCTION_ENABLED';
export const CHANGE_MIN_DIVIDEND = 'SPORT/CHANGE_MIN_DIVIDEND';
export const CHANGE_MAX_DIVIDEND = 'SPORT/CHANGE_MAX_DIVIDEND';
export const REQUEST_DIVIDEND_DISTRIBUTION =
  'SPORT/REQUEST_DIVIDEND_DISTRIBUTION';
export const RECEIVE_DIVIDEND_DISTRIBUTION =
  'SPORT/RECEIVE_DIVIDEND_DISTRIBUTION';
export const RECEIVE_DIVIDEND_DISTRIBUTION_ERROR =
  'SPORT/RECEIVE_DIVIDEND_DISTRIBUTION_ERROR';

// Factor reduction actions
export const DELETE_FACTOR_REDUCTION = 'SPORT/DELETE_FACTOR_REDUCTION';
export const TOGGLE_FACTOR_REDUCTION_ENABLED =
  'SPORT/TOGGLE_FACTOR_REDUCTION_ENABLED';
export const CHANGE_FACTOR = 'SPORT/CHANGE_FACTOR';
export const DELETE_BOMBEN_FACTOR_REDUCTION =
  'SPORT/DELETE_BOMBEN_FACTOR_REDUCTION';
export const TOGGLE_BOMBEN_FACTOR_REDUCTION_ENABLED =
  'SPORT/TOGGLE_BOMBEN_FACTOR_REDUCTION_ENABLED';
export const CHANGE_BOMBEN_FACTOR = 'SPORT/CHANGE_BOMBEN_FACTOR';

// Match-specific reduction action (Bomben only)
export const DELETE_BOMBEN_MATCH_REDUCTION =
  'SPORT/DELETE_BOMBEN_MATCH_REDUCTION';
export const TOGGLE_BOMBEN_MATCH_REDUCTION_ENABLED =
  'SPORT/TOGGLE_BOMBEN_MATCH_REDUCTION_ENABLED';
export const TOGGLE_BOMBEN_MATCH_WINNER_REQUIREMENT =
  'SPORT/TOGGLE_BOMBEN_MATCH_WINNER_REQUIREMENT';
export const CHANGE_BOMBEN_MIN_WINNING_MARGIN =
  'SPORT/CHANGE_BOMBEN_MIN_WINNING_MARGIN';
export const CHANGE_BOMBEN_MAX_WINNING_MARGIN =
  'SPORT/CHANGE_BOMBEN_MAX_WINNING_MARGIN';

// Publish actions
export const REQUEST_SAVED_BET_TIP = 'SPORT/REQUEST_SAVED_BET_TIP';
export const RECEIVE_SAVED_BET_TIP = 'SPORT/RECEIVE_SAVED_BET_TIP';
export const RECEIVE_SAVED_BET_TIP_ERROR = 'SPORT/RECEIVE_SAVE_BET_TIP_ERROR';
export const REQUEST_SAVE_BET_TIP = 'SPORT/REQUEST_SAVE_BET_TIP';
export const RECEIVE_SAVE_BET_TIP = 'SPORT/RECEIVE_SAVE_BET_TIP';
export const RECEIVE_SAVE_BET_TIP_ERROR = 'SPORT/RECEIVE_SAVE_BET_TIP_ERROR';

// Överodds analysis actions
export const REQUEST_DRAW_ANALYSIS = 'SPORT/REQUEST_DRAW_ANALYSIS';
export const RECEIVE_DRAW_ANALYSIS = 'SPORT/RECEIVE_DRAW_ANALYSIS';
export const RECEIVE_DRAW_ANALYSIS_ERROR = 'SPORT/RECEIVE_DRAW_ANALYSIS_ERROR';
export const REQUEST_MATCH_ANALYSIS = 'SPORT/REQUEST_MATCH_ANALYSIS';
export const RECEIVE_MATCH_ANALYSIS = 'SPORT/RECEIVE_MATCH_ANALYSIS';
export const RECEIVE_MATCH_ANALYSIS_ERROR =
  'SPORT/RECEIVE_MATCH_ANALYSIS_ERROR';
