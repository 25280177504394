let data = {};

export async function load() {
  data = await import(/* webpackChunkName: "sv" */ '../sv.json');

  // no.json is only a partial translation file.
  // For Norwegian site, keep Swedish data but overwrite
  // any sv.json fields with no.json
  if (IS_NO_SITE) {
    data = {
      ...data,
      ...(await import(/* webpackChunkName: "no" */ '../no.json')),
    };
  }
}

// Deprecated
export function get(...args) {
  return getText(...args);
}

export function hasText(id) {
  return data[id] ? true : false;
}

export function getText(id, defaultValue) {
  let str = data[id];

  if (str === undefined) {
    str = defaultValue != null ? defaultValue : id;
  }

  if (str && str.replace) {
    for (let n = 1; n < arguments.length; n++) {
      str = str.replace(`%${n}`, arguments[n]);
    }
  }

  return str;
}

export function upperFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitalizeWords(string, wordSeparators = [' ']) {
  if (
    typeof string !== 'string' ||
    (typeof wordSeparators !== 'string' && !Array.isArray(wordSeparators))
  ) {
    return string;
  }
  let capitalizedString = string.toLowerCase();
  for (const separator of wordSeparators) {
    capitalizedString = capitalizedString
      .split(separator)
      .map(upperFirst)
      .join(separator);
  }
  return capitalizedString;
}

export function formatSentence(string) {
  if (typeof string !== 'string' || string === '') {
    return string;
  }
  let sentence = upperFirst(string);
  if (
    !sentence.endsWith('.') &&
    !sentence.endsWith('!') &&
    !sentence.endsWith('?')
  ) {
    sentence += '.';
  }
  return sentence;
}
