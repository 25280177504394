import config from 'config';
import uniqueId from 'lodash/uniqueId';
import { RSAA } from 'redux-api-middleware';

import * as types from '../actionTypes';

export const changeUiScale = scale => ({
  type: types.CHANGE_UI_SCALE,
  payload: scale,
  meta: {
    persist: 'local',
  },
});

export const updatePageVisibility = hidden => ({
  type: types.UPDATE_PAGE_VISIBILITY,
  payload: hidden,
});

export const showLoader = labelProp => ({
  type: types.SHOW_LOADER,
  payload: labelProp,
});

export const hideLoader = () => ({
  type: types.HIDE_LOADER,
});

export const onLocationPathnameChange = pathname => ({
  type: types.LOCATION_PATHNAME_CHANGE,
  payload: pathname,
});

export const addSiteMessage = (messageType, messageProps) => ({
  type: types.ADD_SITE_MESSAGE,
  payload: {
    messageType,
    message: { clientId: uniqueId('message_'), ...messageProps },
  },
});

export function fetchPromo() {
  return {
    [RSAA]: {
      endpoint: `${config.traisApi}/v1/public/content/?limit=1&type=campaign`,
      method: 'GET',
      headers: {
        'TRMedia-API-Key': config.trMediaApiKey,
        site: process.env.REACT_APP_TRAIS_SITE,
      },
      types: [
        types.REQUEST_PROMO_MESSAGE,
        types.RECEIVE_PROMO_MESSAGE,
        {
          type: types.RECEIVE_PROMO_ERROR,
          meta: { errorType: 'discrete' },
        },
      ],
    },
  };
}

export function fetchAlert() {
  return {
    [RSAA]: {
      endpoint: `/api/content/alert`,
      method: 'GET',
      types: [
        types.REQUEST_ALERT_MESSAGE,
        types.RECEIVE_ALERT_MESSAGE,
        {
          type: types.RECEIVE_ALERT_ERROR,
          meta: { errorType: 'discrete' },
        },
      ],
    },
  };
}

export const dismissSiteMessageByServerId = message => ({
  type: types.DISMISS_SITE_MESSAGE_BY_SERVER_ID,
  payload: message,
  meta: {
    persist: 'local',
  },
});

export const dismissSiteMessageByClientId = message => ({
  type: types.DISMISS_SITE_MESSAGE_BY_CLIENT_ID,
  payload: message,
});

export const dismissSiteMessageByType = messageType => ({
  type: types.DISMISS_SITE_MESSAGE_BY_TYPE,
  payload: messageType,
});

export const toggleUserTemplatesVisibility = () => ({
  type: types.TOGGLE_USER_TEMPLATES_VISIBILITY,
  meta: {
    persist: 'local',
  },
});

export const toggleJokerTemplatesVisibility = () => ({
  type: types.TOGGLE_JOKER_TEMPLATES_VISIBILITY,
  meta: {
    persist: 'local',
  },
});
